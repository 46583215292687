<template>
  <div class="container">
    <BasicLogo width="300" />
    <div class="content">
      <div class="content-form">
        <BasicInput
          v-model="user.email"
          label="Usuario"
          @keyup.enter="onLogin"
        />
        <FormError :show="userError.email" message="Ingrese un email válido" />
        <BasicInput
          v-model="user.password"
          label="Contraseña"
          type="password"
          @keyup.enter="onLogin"
        />
        <FormError :show="userError.password" message="Ingrese su contraseña" />
        <div class="content-forgot">
          <router-link to="/forgot">¿Olvidó su contraseña?</router-link>
        </div>
      </div>
      <div class="content-submit">
        <PrimaryButton label="Login" :click="onLogin" />
      </div>
      <!-- <p class="content-access">
        Solicite su acceso
        <router-link to="/access"><strong>aquí.</strong></router-link>
      </p> -->
    </div>
    <!-- <div class="separator">
      <AppDesktop />
    </div>
    <AppMobile /> -->
    <!-- <div class="content contact" id="contact">
      <SeparatorTitle title="Póngase en contacto" />
      <div class="content-form">
        <BorderInput v-model="contact.name" label="Nombre *" />
        <FormError :show="contactError.name" message="Ingrese su nombre" />
        <BorderInput v-model="contact.email" label="Email *" />
        <FormError
          :show="contactError.email"
          message="Ingrese un email válido"
        />
        <BasicLabel label="Su mensaje *" />
        <OutlinedTextArea v-model="contact.message" />
        <FormError
          :show="contactError.message"
          message="El mensaje debe contener entre 10 y 100 caracteres"
        />
      </div>
      <div class="content-submit">
        <SecondaryButton label="Enviar" :click="onContact" />
      </div>
    </div> -->
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BasicInput from "../../widgets/input/BasicInput";
import BorderInput from "../../widgets/input/BorderInput";
import BasicLabel from "../../widgets/label/BasicLabel";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import SecondaryButton from "../../widgets/button/SecondaryButton";
import FormError from "../../widgets/error/FormError";
import AppMobile from "../../widgets/app/AppMobile.vue";
import AppDesktop from "../../widgets/app/AppDesktop.vue";
import SeparatorTitle from "../../widgets/title/SeparatorTitle.vue";
import store from "../../store";
import { useRouter } from "vue-router";
import { reactive, watchEffect } from "vue";
import { validation } from "../../constants";

export default {
  components: {
    BasicLogo,
    BasicInput,
    BorderInput,
    BasicLabel,
    OutlinedTextArea,
    PrimaryButton,
    SecondaryButton,
    FormError,
    AppMobile,
    AppDesktop,
    SeparatorTitle,
  },
  setup() {
    const router = useRouter();
    const user = reactive({
      email: "",
      password: "",
    });
    const userError = reactive({
      email: false,
      password: false,
    });

    watchEffect(() => {
      if (user.email && validation.emailRegex.test(user.email))
        userError.email = false;
      if (user.password) userError.password = false;
    });

    const validateLogin = () => {
      let valid = true;

      if (!user.email || !validation.emailRegex.test(user.email)) {
        userError.email = true;
        valid = false;
      }
      if (!user.password) {
        userError.password = true;
        valid = false;
      }

      return valid;
    };

    const onLogin = async () => {
      if (!validateLogin()) return;

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_SIGNIN", user);
      store.commit("setLoading", false);
      if (response.ok) {
        sessionStorage.setItem("token", response.token);
        location.href = "/home";
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const contact = reactive({
      name: "",
      email: "",
      message: "",
    });
    const contactError = reactive({
      name: false,
      email: false,
      message: false,
    });

    watchEffect(() => {
      if (contact.name) contactError.name = false;
      if (contact.email && validation.emailRegex.test(contact.email))
        contactError.email = false;
      if (
        contact.message &&
        contact.message.length >= 10 &&
        contact.message.length <= 100
      )
        contactError.message = false;
    });

    const validateContact = () => {
      let valid = true;

      if (!contact.name) {
        contactError.name = true;
        valid = false;
      }
      if (!contact.email || !validation.emailRegex.test(contact.email)) {
        contactError.email = true;
        valid = false;
      }
      if (
        !contact.message ||
        contact.message.length < 10 ||
        contact.message.length > 100
      ) {
        contactError.message = true;
        valid = false;
      }

      return valid;
    };

    const onContact = async () => {
      if (!validateContact()) return;

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_CONTACT", contact);
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    return { user, userError, contact, contactError, onLogin, onContact };
  },
};
</script>

<style scoped>
.content {
  margin: 2rem 0;
}
.content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-forgot {
  width: 350px;
  text-align: end;
  margin-bottom: 2rem;
}
.content-forgot > a {
  color: var(--primary);
}
.content-submit {
  display: flex;
  justify-content: center;
  margin: 2rem 0 6rem;
}
.content-access {
  text-align: center;
  color: var(--primary);
}
.content-access > a {
  color: var(--primary);
  cursor: pointer;
}
.separator {
  height: 300px;
  background-image: url("../../assets/images/main.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.contact {
  margin-top: 8rem;
}
@media screen and (min-width: 768px) {
  .separator {
    height: 450px;
  }
}
@media screen and (min-width: 992px) {
  .separator {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4rem;
  }
  .contact {
    margin-top: 0;
  }
}
@media screen and (min-width: 1200px) {
  .separator {
    height: 650px;
    margin-top: -10rem;
  }
}
@media screen and (min-width: 1440px) {
  .separator {
    height: 800px;
  }
}
@media screen and (min-width: 1880px) {
  .separator {
    height: 1000px;
    margin-top: -18rem;
  }
}
</style>
