<template>
  <div class="app">
    <div class="app-content">
      <div class="app-icon">
        <img
          width="50"
          src="../../assets/images/iconoApp.png"
          alt="Logo App TuAppto"
          class="logo"
        />
        <p class="login-main-tuappto">Tu<strong>Appto</strong></p>
      </div>
      <p>Consigue nuestra app para dispositivos móviles</p>
      <div class="app-buttons">
        <img
          width="70"
          src="../../assets/images/googlePlay.png"
          alt="Google Play"
          class="google"
        />
        <img
          width="70"
          src="../../assets/images/appleStore.png"
          alt="Apple Store"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.app {
  align-items: center;
  display: none;
}
.app > p {
  color: var(--primary-dark);
  font-size: 1.2rem;
}
.app-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 6rem;
}
.app-icon {
  display: flex;
  align-items: center;
}
.app-icon > .logo {
  border: 4px solid var(--primary-dark);
  border-radius: .7rem;
  background-color: var(--primary-dark);
}
.app-icon > p {
  margin-left: .5rem;
  font-size: 1.5rem;
  color: var(--primary-dark);
}
.app-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-buttons > .google {
  margin-right: .5rem;
}
@media screen and (min-width: 992px) {
  .app {
    display: block;
    width: 100%;
  }
  .app-content {
    margin: 6rem 10rem 0;
  }
}
@media screen and (min-width: 1200px) {
  .app-content {
    margin: 8rem 12rem 0;
  }
}
@media screen and (min-width: 1440px) {
  .app-content {
    margin: 10rem 20rem 0;
  }
}
@media screen and (min-width: 1880px) {
  .app-content {
    margin: 14rem 30rem 0;
  }
}
</style>